import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import Script from 'next/script'
import { useMemo } from 'react'
import Modal from 'react-modal'
import { ToastContainer } from 'react-toastify'
import { SharedLoadingIndicatorContextProvider, SharedProgressLoadingIndicator } from 'shared-loading-indicator'
import { PageNavigationLoadingTracker } from '../components/PageNavigationLoadingTracker'
import { PageThemeContext } from '../contexts/pageThemeContext'
import '../styles/globals.sass'
import { api } from '../utilities/api'
import type { PageProps } from './[[...path]]'

const inter = Inter({
	weight: 'variable',
	subsets: ['latin', 'latin-ext'],
})

if ('document' in globalThis) {
	const root = document.querySelector('#__next')
	if (root instanceof HTMLElement) {
		Modal.setAppElement(root)
	}
}

function MyApp({ Component, pageProps }: AppProps<PageProps>) {
	const reactQueryClient = useMemo(() => {
		return new QueryClient()
	}, [])
	return (
		<>
			<Script id="hotjar-script" strategy="afterInteractive">{`(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:4940536,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=')`}</Script>

			<Script id="gtm-tracking" strategy="afterInteractive">{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
					new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
					j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
					'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
					})(window,document,'script','dataLayer','GTM-KZ3NH2')`}</Script>

			<Script
				id="facebookPixel"
				strategy="afterInteractive"
			>{`!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
				n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
				n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
				t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
				document,'script','https://connect.facebook.net/en_US/fbevents.js');
				fbq('init', '1000356583367032');
				fbq('track', "PageView");
			`}</Script>

			<Script id="seznamSklik" type="text/javascript" src="https://c.seznam.cz/js/rc.js" />
			<Script id="seznamSklik2">
				{`var retargetingConf = {
					rtgId: 124929
				};
				if (window.rc && window.rc.retargetingHit) {
					window.rc.retargetingHit(retargetingConf);
				}`}
			</Script>

			<QueryClientProvider client={reactQueryClient}>
				<style jsx global>{`
					html {
						font-family: ${inter.style.fontFamily};
					}
					body {
						background: ${pageProps.theme?.backgroundColor};
						color: ${pageProps.theme?.textColor};
						--theme-box-color: ${pageProps.theme?.name !== 'Default'
							? 'rgba(255, 255, 255, 0.1)'
							: 'rgba(0, 0, 0, 0.075)'};
					}
				`}</style>
				<SharedLoadingIndicatorContextProvider>
					<PageThemeContext.Provider value={pageProps.theme ?? undefined}>
						<PageNavigationLoadingTracker />
						<SharedProgressLoadingIndicator />
						<ToastContainer />
						<Component {...pageProps} />
					</PageThemeContext.Provider>
				</SharedLoadingIndicatorContextProvider>
			</QueryClientProvider>
		</>
	)
}

export default api.withTRPC(MyApp)
